import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import LogRocket from 'logrocket';
import { nanoid } from 'nanoid';
import { useGate } from 'statsig-react';

import {
  createInstantSurvey,
  DEFAULT_GROUP_NAME,
  DEFAULT_RESPONSE_COUNT,
  MAINTENANCE_MESSAGE,
  readInstantSurvey,
  readInstantSurveyType,
  updateInstantSurvey,
} from '@api/instant_survey';
import { readSurvey, readSurveyType } from '@api/surveys';

import { GATES } from '@constants';
import { isVerifiedVoices } from '@helpers';
import { updateUserCreditsRemaining } from '@redux/slices/user';
import { Error, LoaderSkeleton, MaintenanceView, Stepper } from '@utilities';

import Confirmation from './components/Confirmation';
import Panelists from './components/Panelists';
import Questionnaire from './components/Questionnaire';
import Review from './components/Review';

import { SURVEY_STEPS, SURVEY_TEMPORARY_TITLE } from './utilities/constants';
import { SurveyContext } from './utilities/context';

import { ReactComponent as NumeratorLogoVerifiedVoices } from '../../components/Header/assets/verified-voices-logo.svg';
import { ReactComponent as NumeratorLogo } from '../../components/Header/assets/data-wave-logo.svg';

const CreateInstantSurvey = () => {
  const [activeStep, setActiveStep] = useState(SURVEY_STEPS.PANELISTS);
  const [error, setError] = useState();
  const [hasLaunched, setHasLaunched] = useState(false);
  const [insightsDocument, setInsightsDocument] = useState();
  const [insightsDocumentType, setInsightsDocumentType] = useState();
  const [instantSurveyType, setInstantSurveyType] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const { isLoading: isStatsigLoading, value: isInstantSurveyMaintenance } = useGate(
    GATES.INSTANT_SURVEY_MAINTENANCE
  );

  const { instant_survey_type_id: instantSurveyTypeId } = useParams();
  const panel = useSelector((state) => state?.user?.data?.panel);
  const [survey, setSurvey] = useState({
    country: panel?.abbrev?.toLowerCase(),
    groups: [
      {
        id: nanoid(),
        name: DEFAULT_GROUP_NAME,
        promptAnswers: [],
        quotaLimit: DEFAULT_RESPONSE_COUNT,
      },
    ],
    id: null,
    instantSurveyType: instantSurveyTypeId,
    questionLogic: {},
    questions: [],
    title: '',
  });

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!isStatsigLoading && !isInstantSurveyMaintenance) {
      fetchSurveyData();
    }
  }, [isStatsigLoading]);

  const fetchSurveyData = async () => {
    try {
      const surveyRequests = [
        readInstantSurveyType({ id: instantSurveyTypeId }),
        readSurveyType({ documentId: instantSurveyTypeId }),
      ];
      const insightsId = searchParams.get('id');
      const instantSurveyId = searchParams.get('ref');

      if (insightsId && instantSurveyId) {
        surveyRequests.push(
          readInstantSurvey({ id: instantSurveyId }),
          readSurvey({ id: insightsId })
        );
      }

      const [
        instantSurveyTypeResponse,
        insightsDocumentTypeResponse,
        instantSurveyDraftResponse,
        insightsDocumentResponse,
      ] = await Promise.all(surveyRequests);

      setInstantSurveyType(instantSurveyTypeResponse);
      setInsightsDocumentType({
        ...insightsDocumentTypeResponse,
        samplePricing: insightsDocumentTypeResponse.samplePricing.filter(
          ({ isAskWhy, type }) => !isAskWhy && type === 'CREDITS'
        ),
      });

      if (insightsId && instantSurveyId) {
        const { hasQuestionLogicError, ...surveyData } = instantSurveyDraftResponse;
        // if (hasQuestionLogicError) {
        //   toast(
        //     'There was an error while rebuilding your questionnaire logic. Please confirm your selections before continuing.',
        //     { status: 'error' }
        //   );
        //   setHadQuestionLogicError(true);
        // }

        setInsightsDocument(insightsDocumentResponse);
        setSurvey(surveyData);
      }

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const handleSurveyPublish = ({ userCreditsRemaining }) => {
    LogRocket.track('InstantSurvey_Published', { type: instantSurveyTypeId });
    dispatch(updateUserCreditsRemaining({ userCreditsRemaining }));
    setHasLaunched(true);
  };

  const saveInstantSurvey = async ({ signal } = {}) => {
    const { id, title, ...surveyData } = survey;
    let response;

    if (!id) {
      response = await createInstantSurvey({
        instantSurvey: {
          ...surveyData,
          status: 'DRAFT',
          title: title || SURVEY_TEMPORARY_TITLE,
        },
        signal,
      });
    } else {
      response = await updateInstantSurvey({
        instantSurvey: {
          ...surveyData,
          id,
          title: title || SURVEY_TEMPORARY_TITLE,
        },
        signal,
      });
    }

    const { title: savedSurveyTitle, ...surveyReponse } = response;
    setSurvey({
      title: savedSurveyTitle === SURVEY_TEMPORARY_TITLE ? '' : savedSurveyTitle,
      ...surveyReponse,
    });

    return response;
  };

  const updateSurvey = (fields) => {
    // callback function necessary as consecutive update calls are necessary when reordering questions
    setSurvey((surveyState) => ({ ...surveyState, ...fields }));
  };

  if (isInstantSurveyMaintenance) {
    return (
      <MaintenanceView
        heading={MAINTENANCE_MESSAGE}
        logo={isVerifiedVoices() ? <NumeratorLogoVerifiedVoices /> : <NumeratorLogo />}
      />
    );
  }
  if (error) return <Error status={error?.response?.status} />;
  if (hasLaunched) return <Confirmation />;
  if (isLoading || isStatsigLoading) {
    return (
      <LoaderSkeleton height={450}>
        <rect x="0" y="0" rx="4" ry="4" width="1333" height="40" />
        <rect x="0" y="60" rx="4" ry="4" width="1333" height="130" />
        <rect x="0" y="210" rx="4" ry="4" width="1333" height="40" />
        <rect x="0" y="270" rx="4" ry="4" width="1333" height="20" />
        <rect x="0" y="310" rx="2" ry="2" width="175" height="20" />
        <rect x="0" y="350" rx="2" ry="2" width="200" height="15" />
        <rect x="0" y="375" rx="2" ry="2" width="175" height="15" />
        <rect x="0" y="400" rx="2" ry="2" width="225" height="15" />
        <rect x="0" y="425" rx="2" ry="2" width="200" height="15" />
        <rect x="0" y="450" rx="2" ry="2" width="225" height="15" />
      </LoaderSkeleton>
    );
  }

  return (
    <SurveyContext.Provider
      value={{
        activeStep,
        insightsDocument,
        insightsDocumentType,
        instantSurveyType,
        maxQuestions: insightsDocumentType.maxQuestions,
        saveInstantSurvey,
        survey,
        updateSurvey,
      }}
    >
      <Stepper
        activeIndex={activeStep}
        navigationType="linear"
        onChange={(index) => setActiveStep(index)}
        steps={[
          {
            isComplete: activeStep > 0,
            label: 'Select Panelists',
          },
          {
            isComplete: survey.questions.length >= 1,
            label: 'Create Questionnaire',
          },
          {
            label: 'Review Survey',
          },
        ]}
      />
      {activeStep === SURVEY_STEPS.PANELISTS && (
        <Panelists onNext={() => setActiveStep(SURVEY_STEPS.QUESTIONNAIRE)} />
      )}
      {activeStep === SURVEY_STEPS.QUESTIONNAIRE && (
        <Questionnaire
          onNext={() => setActiveStep(SURVEY_STEPS.REVIEW)}
          onPrevious={() => setActiveStep(SURVEY_STEPS.PANELISTS)}
        />
      )}
      {activeStep === SURVEY_STEPS.REVIEW && (
        <Review onPublish={handleSurveyPublish} setActiveStep={setActiveStep} />
      )}
    </SurveyContext.Provider>
  );
};

export default CreateInstantSurvey;

CreateInstantSurvey.propTypes = {};
