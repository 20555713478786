/**
 * Format the date to be more human readable.
 * @returns {string}
 */
export const formatDate = () => {
  const today = new Date();
  return new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(today);
};

/**
 * transforms and formats chart data format to pptxGen format
 * @param {String} chartDataFormat
 * @return {String}
 */
export const transformToPptxGenFormat = (chartDataFormat) => {
  switch (chartDataFormat) {
    case '0.0%':
      return '0.0%';
    case '0,0.0':
      return '#,##0.0';
    case '0,0.0%':
      return '#,##0.0%';
    case '+0.0':
      return '+0.0';
    case '0.0':
      return '0.0';
    case '0,0':
      return '#,##0';
    case '$0,0':
      return '$#,##0';
    case '$0,0.00':
      return '$#,##0.00';
    case '0.00%':
      return '0.00%';
    default:
      return '#,##0';
  }
};
