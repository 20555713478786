import PropTypes from 'prop-types';
import { numeratorFullLogo } from '@utilities/assets';
import { formatDate } from '../../utilities/helpers';

const TitleSlide = ({ pptx, title }) => {
  const slide = pptx.addSlide();

  const textProps = {
    align: 'center',
    color: '064a51',
    fontFace: 'Arial',
  };

  slide.addText(title.name, {
    ...textProps,
    bold: true,
    fontSize: 52,
    h: 1,
    lineSpacing: 50,
    w: '80%',
    x: '10%',
    y: 1.8,
  });

  slide.addText(`${title.reportType} - ${formatDate()}`, {
    ...textProps,
    bold: false,
    fontSize: 28,
    h: 1,
    w: '80%',
    x: '10%',
    y: 2.8,
  });

  slide.addShape(pptx.ShapeType.rect, {
    fill: { color: '00e36b' },
    h: 0.1,
    w: '100%',
    x: 0,
    y: '98.5%',
  });

  slide.addImage({
    data: numeratorFullLogo,
    h: 0.1903,
    w: 1.1,
    x: '83%',
    y: '90%',
  });

  return slide;
};

TitleSlide.propTypes = {
  pptx: PropTypes.object.isRequired,
  title: PropTypes.shape({
    name: PropTypes.string.isRequired,
    reportType: PropTypes.string.isRequired,
  }).isRequired,
};

export default TitleSlide;
