import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { OverlayPopover } from '@utilities';

import DownloadPowerPoint from './components/DownloadPowerPoint';
import DownloadXLSX from './components/DownloadXLSX';

const Download = ({
  isAvailable,
  isDownloadDropdownActive,
  powerpointDownloadData,
  reportId,
  setIsDownloadDropdownActive,
  target,
}) => {
  let { job_id: jobId } = useParams();
  const id = reportId ? reportId : jobId;

  if (!isAvailable) {
    return null;
  }

  return (
    <OverlayPopover
      header="Download Options"
      isActive={isDownloadDropdownActive}
      onHide={() => setIsDownloadDropdownActive(false)}
      target={target}
    >
      <ul data-testid="download-options-popover">
        <li>
          <DownloadXLSX id={id} />
        </li>
        {process.env.REACT_APP_EXPORT_PPT &&
          powerpointDownloadData?.title?.reportType === 'Shopper Metrics' && (
            <li>
              <DownloadPowerPoint data={powerpointDownloadData} />
            </li>
          )}
      </ul>
    </OverlayPopover>
  );
};

Download.propTypes = {
  isAvailable: PropTypes.bool,
  isDownloadDropdownActive: PropTypes.bool,
  powerpointDownloadData: PropTypes.object,
  reportId: PropTypes.number,
  setIsDownloadDropdownActive: PropTypes.func,
  target: PropTypes.object,
};

export default Download;
