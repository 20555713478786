import PropTypes from 'prop-types';

import { numeratorFullLogo } from '@utilities/assets';

import { transformToPptxGenFormat } from '../../utilities/helpers';

const ChartSlide = ({ chart, reportUrl, pptx }) => {
  const chartData = chart && getChartData(chart);
  if (!chartData) return null;
  const slide = pptx.addSlide();
  const title = chart?.series[1]?.name || chart?.series[0]?.name;

  slide.addText(`${chart?.type} for ${title}`, {
    bold: true,
    color: '064a51',
    fontFace: 'Arial',
    fontSize: 20,
    x: 0.5,
    y: 0.5,
  });

  slide.addChart(pptx.charts.BAR, chartData.dataSeries, chartData.chartOptions);
  slide.addShape(pptx.ShapeType.rect, {
    fill: { color: '00e36b' },
    h: 0.1,
    w: '100%',
    x: 0,
    y: '98.5%',
  });

  slide.addText('Link to the report:', {
    color: '5c8488',
    fontFace: 'Arial',
    fontSize: 10,
    italic: true,
    x: 0.5,
    y: 5.2,
  });

  slide.addText(
    [
      {
        text: reportUrl,
        options: {
          hyperlink: {
            url: reportUrl,
          },
        },
      },
    ],
    {
      color: '00e36b',
      fontFace: 'Arial',
      fontSize: 10,
      italic: true,
      x: 1.6,
      y: 5.2,
    }
  );

  slide.addImage({
    data: numeratorFullLogo,
    h: 0.1903,
    w: 1.1,
    x: '83%',
    y: '90%',
  });

  return slide;
};

const getChartData = (chart) => {
  if (!chart || !chart.series) return null;

  const seriesLength = chart.series?.[0]?.data?.length || 0;
  const labels = chart.xAxis?.categories?.slice(0, seriesLength) || [];
  const dataSeries = chart.series?.map((series) => ({
    name: series.name,
    labels,
    values: series.data || [],
  }));

  const maxVal = Math.max(...chart.series?.flatMap((s) => s.data)) || 0;
  const format = transformToPptxGenFormat(chart.format);
  const labelColor = '000000';

  return {
    dataSeries,
    chartOptions: {
      barDir: 'col',
      barGapWidthPct: 50,
      barOverlapPct: -10,
      catAxisLabelColor: labelColor,
      catAxisLabelFontSize: 8,
      catAxisLabelRotate: -45,
      catAxisLineShow: false,
      chartColors: ['064a51', '24e170'].slice(0, chart.series?.length || 1),
      dataLabelFontSize: 6,
      dataLabelFormatCode: format,
      h: '75%',
      legendColor: '064a51',
      legendFontSize: 16,
      legendPos: 't',
      showLegend: true,
      showValue: true,
      valAxisLabelColor: labelColor,
      valAxisLabelFontSize: 10,
      valAxisLabelFormatCode: format,
      valAxisLineShow: false,
      valAxisMaxVal: maxVal * 1.2,
      valAxisMinVal: 0,
      valGridLine: { color: 'cccccc', size: 0.5, style: 'solid' },
      w: '75%',
      x: 1,
      y: 0.7,
    },
  };
};

ChartSlide.propTypes = {
  chart: PropTypes.shape({
    format: PropTypes.string,
    series: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.number),
        name: PropTypes.string,
      })
    ),
    type: PropTypes.string,
    xAxis: PropTypes.shape({
      categories: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  pptx: PropTypes.object.isRequired,
  reportUrl: PropTypes.string.isRequired,
};

export default ChartSlide;
