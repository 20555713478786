import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Announcement,
  EditableTitle,
  Error,
  ExclamationTriangleIcon,
  LoaderSkeleton,
  PageTitle,
  Tabs,
} from '@utilities';

import { ASK_WHY_SURVEY_TYPE_ID, readInstantSurveyType } from '@api/instant_survey';
import { readReport, updateReportName } from '@api/reports';
import { readSurveyType } from '@api/surveys';
import { reportStatus } from '@helpers';

import AskWhyTab from './components/AskWhyTab';
import OmniCharacteristicsBanner from './components/OmniCharacteristicsBanner';
import Prompts from '../../components/Prompts';
import ReportStatus from '../../components/ReportStatus';
import SurveyGPT from './components/SurveyGPT';
import Tab from './components/Tab';
import Toolbar from '../../components/Builder/components/Toolbar';

const Report = ({ id, publicToken }) => {
  const [askWhyInsightsDocument, setAskWhyInsightsDocument] = useState();
  const [askWhySurveyType, setAskWhySurveyType] = useState();
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [isReportCompleted, setIsReportCompleted] = useState(false);
  const [name, setName] = useState(null);
  const [newComment, setNewComment] = useState({});
  const [newName, setNewName] = useState(null);
  const [powerpointDownloadData, setPowerpointDownloadData] = useState(null);

  let { job_id: reportId, tab_id: tabId } = useParams();
  const canAccessAskWhy = useSelector((state) => state.user.data?.permissions?.canAccessAskWhy);
  const reduxReport = useSelector((state) =>
    state?.websocket?.reports?.find((report) => Number(report?.id) === Number(reportId))
  );

  const controller = new AbortController();
  const navigate = useNavigate();
  const signal = controller.signal;

  reportId = parseInt(id || reportId);
  tabId = tabId === 'ask-why' ? tabId : parseInt(tabId);

  const exportData = useMemo(() => {
    return {
      reportName: name || data?.name,
      reportType: data?.documentId,
      tabName: data?.tabs.find((tab) => tab?.id === tabId)?.label,
    };
  }, [data, tabId]);

  const getData = async () => {
    setData(null);
    try {
      const response = await readReport({ publicToken, reportId, signal });

      if (!tabId) {
        navigate(
          publicToken
            ? `/share/${publicToken}/${response?.data?.tabs[0]?.id}`
            : `/dashboard/report/${reportId}/${response?.data?.tabs[0]?.id}`,
          { replace: true }
        );
      }

      if (
        response?.data?.status === reportStatus.COMPLETED ||
        response?.data?.status === reportStatus.OUT_OF_DATE
      ) {
        setIsReportCompleted(true);
      }

      setData(response?.data);
    } catch (error) {
      setError(error);
    }
  };

  const getAskWhySurveyType = async ({ signal }) => {
    try {
      const [askWhyInsightsDocument, askWhyInstantSurveyType] = await Promise.all([
        readSurveyType({
          documentId: ASK_WHY_SURVEY_TYPE_ID,
          signal,
        }),
        readInstantSurveyType({
          id: ASK_WHY_SURVEY_TYPE_ID,
          signal,
        }),
      ]);
      const { samplePricing, ...surveyType } = askWhyInsightsDocument;
      setAskWhyInsightsDocument({
        samplePricing: samplePricing.filter((price) => price?.isAskWhy),
        ...surveyType,
      });
      setAskWhySurveyType(askWhyInstantSurveyType);
    } catch (error) {
      if (!signal?.aborted) {
        // log error but do not prevent the page from loading
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const askWhyController = new AbortController();
    if (canAccessAskWhy) {
      getAskWhySurveyType({ signal: askWhyController.signal });
    }

    return () => askWhyController.abort();
  }, [canAccessAskWhy]);

  useEffect(() => {
    getData();

    return () => controller.abort();
  }, [reportId]);

  useEffect(() => {
    if (reduxReport?.surveyGPTJobId && data) {
      setData({
        ...data,
        surveyGPTJobId: reduxReport.surveyGPTJobId,
      });
    }
    if (reduxReport?.characteristicsJobId && data) {
      setData({
        ...data,
        tags: data?.tags?.includes('CHARACTERISTICS')
          ? data?.tags
          : ['CHARACTERISTICS', ...data?.tags],
      });
    }
  }, [reduxReport]);

  useEffect(() => {
    if (data && !tabId) {
      navigate(
        publicToken
          ? `/share/${publicToken}/${data?.tabs[0]?.id}`
          : `/dashboard/report/${reportId}/${data?.tabs[0]?.id}`,
        { replace: true }
      );
    }
  }, [tabId]);

  useEffect(() => {
    if (!newName) return;

    try {
      updateReportName({ name: newName, id: reportId });
    } catch (error) {
      console.error(error);
    }
  }, [newName]);

  const onAddNewComment = (comment) => {
    setNewComment(comment);
  };

  const onAskWhyCreate = useCallback(() => {
    getData();
    navigate(`/dashboard/report/${reportId}/ask-why`);
  }, [reportId]);

  const hasOmniCharacteristicsBanner = data?.tags?.includes('CHARACTERISTICS');
  const hasOutdatedSurveyBanner = data?.tags?.includes('SURVEY_OUTDATED');

  if (error) return <Error status={error?.response?.status} />;

  if (!data)
    return (
      <LoaderSkeleton height={725}>
        <rect x="0" y="0" rx="4" ry="4" width="1333" height="130" />
        <rect x="0" y="145" rx="4" ry="4" width="1333" height="40" />
        <rect x="0" y="200" rx="4" ry="4" width="250" height="50" />
        <rect x="275" y="200" rx="4" ry="4" width="250" height="50" />
        <rect x="0" y="265" rx="4" ry="4" width="1333" height="30" />
        <rect x="0" y="310" rx="4" ry="4" width="1333" height="30" />
        <rect x="0" y="355" rx="4" ry="4" width="1333" height="30" />
        <rect x="0" y="400" rx="4" ry="4" width="1333" height="30" />
        <rect x="0" y="445" rx="4" ry="4" width="1333" height="30" />
        <rect x="0" y="525" rx="4" ry="4" width="1333" height="200" />
      </LoaderSkeleton>
    );

  return (
    <>
      <PageTitle title={name || data?.name} />
      <Toolbar
        answers={data?.answers}
        askWhyInsightsDocument={askWhyInsightsDocument}
        askWhyInstantSurveys={data?.askWhyInstantSurveys}
        askWhyPeopleGroups={data?.askWhyPeopleGroups}
        askWhySurveyType={askWhySurveyType}
        documentId={data?.documentId}
        helpUrl={data?.helpUrl}
        instantSurveyId={data?.instantSurveyId}
        isCompleted
        isDownloadAvailable={isReportCompleted}
        isFavorite={data?.isFavorite}
        isOwner={data?.isOwner}
        isReadOnly={data?.isReadOnly}
        onAddNewComment={onAddNewComment}
        onAskWhySubmit={onAskWhyCreate}
        peopleGroupTypes={data?.peopleGroupTypes}
        name={name || data?.name}
        powerpointDownloadData={powerpointDownloadData}
        reportId={reportId}
        repromptUrl={`/dashboard/create-report/${data?.documentId}?job_id=${reportId}`}
        tabId={tabId === 'ask-why' ? null : tabId}
        {...data?.permissions}
      >
        <EditableTitle
          autoFocus={false}
          isDisabled={data?.isReadOnly || !data?.isOwner}
          onBlur={setNewName}
          onChange={setName}
          value={name !== null ? name : data?.name}
        />
      </Toolbar>
      <Prompts
        advancedPrompts={data?.advancedPrompts}
        answers={data?.answers}
        isCompleted
        madlib={data?.madlib}
        madlibAdditional={data?.madlibAdditional}
        prompts={data?.prompts}
        status={
          data?.status < reportStatus.COMPLETED && (
            <ReportStatus onComplete={() => setIsReportCompleted(true)} report={data} />
          )
        }
      />
      {data?.tabs?.length > 1 && (
        <Tabs
          routes={data?.tabs?.map((tab) => {
            return {
              label: tab?.label,
              to: publicToken
                ? `/share/${publicToken}/${tab?.id}`
                : `/dashboard/report/${reportId}/${tab?.id}`,
            };
          })}
        />
      )}

      {hasOmniCharacteristicsBanner && <OmniCharacteristicsBanner reportId={reportId} />}
      {hasOutdatedSurveyBanner && (
        <Announcement
          hasClose
          icon={<ExclamationTriangleIcon />}
          text="This survey may have new data, refresh the report to update with new responses"
          variant="warn"
        />
      )}
      {data?.surveyGPTJobId && (
        <SurveyGPT documentId={data?.documentId} surveyGPTJobId={data.surveyGPTJobId} />
      )}
      {tabId && tabId !== 'ask-why' && (
        <Tab
          answers={data?.answers}
          askWhyInsightsDocument={askWhyInsightsDocument}
          askWhyInstantSurveys={data?.askWhyInstantSurveys}
          askWhyPeopleGroups={data?.askWhyPeopleGroups}
          askWhySurveyType={askWhySurveyType}
          createdDateTime={data?.createdDateTime}
          documentId={data?.documentId}
          exportData={exportData}
          isReadOnly={data?.isReadOnly}
          newComment={newComment}
          onAskWhySubmit={onAskWhyCreate}
          peopleGroupTypes={data?.peopleGroupTypes}
          publicToken={publicToken}
          reportId={reportId}
          setPowerpointDownloadData={setPowerpointDownloadData}
          tabId={tabId}
        />
      )}
      {tabId === 'ask-why' && <AskWhyTab askWhyInstantSurveys={data?.askWhyInstantSurveys} />}
    </>
  );
};

Report.propTypes = {
  /**
   * Optional: only required for sharing the report publicly
   */
  id: PropTypes.number,
  /**
   * Optional: only required for sharing the report publicly
   */
  publicToken: PropTypes.string,
};

export default Report;
