import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Error, LoaderSkeleton, LoaderView } from '@utilities';
import { tabStatus } from '@helpers';

import { cancelReport, readReportTab } from '@api/reports';

import Comments from './components/Comments';
import KeyTakeaways from './components/KeyTakeaways';
import Visualizations from './components/Visualizations';

import { getTabStatus, getTabStatusMessage } from './utilities/helpers';

import styles from './_index.module.scss';

export const LOADING_MESSAGES = [
  "Learn even more about this report and all the features of Insights, <a href='https://help.insights.numerator.com/en/' rel='noreferrer' target='_blank'>here</a>",
  'There is a fine line between a numerator and a denominator… but only a fraction of you will have time to process that before your report finishes running.',
  "Click <a href='https://www.numerator.com/resources?utm_source=insights.numerator.com&utm_medium=referral&utm_campaign=report-processing' rel='noreferrer' target='_blank'>here</a> to see the latest analysis & content from across Numerator",
  'Your report is pulled from a list of over 400,000 receipts transcribed daily!',
];

const Tab = ({
  answers,
  askWhyInsightsDocument,
  askWhyInstantSurveys,
  askWhyPeopleGroups,
  askWhySurveyType,
  createdDateTime,
  documentId,
  exportData,
  hasComments,
  isReadOnly,
  newComment,
  onAskWhySubmit,
  onComplete,
  peopleGroupTypes,
  setPowerpointDownloadData,
  publicToken,
  reportId,
  tabId,
}) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [prevProgress, setPrevProgress] = useState(0);
  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();

  const report = useSelector((state) =>
    state?.websocket?.reports?.find((report) => report?.id === reportId)
  );
  const tabs = useSelector((state) => state?.websocket?.tabs);

  const status = getTabStatus({ report, tabId });
  const tabReport = tabs?.find((tab) => tab.reportId === reportId && tab.tabId === tabId);
  const newProgress = tabReport?.progress ? tabReport?.progress : 0;

  const handleCancelReport = async () => {
    try {
      await cancelReport({ reportId });

      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (status === tabStatus.COMPLETED) {
      setIsProcessing(false);
    }
  }, [status]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const getData = async () => {
      setData(null);
      setError(null);

      try {
        const response = await readReportTab({ publicToken, reportId, signal, tabId });

        if (response?.data?.status < tabStatus.COMPLETED) {
          setIsProcessing(true);
        } else {
          setData(response?.data);
          onComplete();
        }
      } catch (error) {
        console.error(error);

        if (error?.response) {
          setError(error);
        }
      }
    };

    if (tabId && !isProcessing) {
      getData();
    }

    return () => controller.abort();
  }, [isProcessing, tabId]);

  useEffect(() => {
    setIsProcessing(false);
  }, [tabId]);

  useEffect(() => {
    newProgress > 95 ? setProgress(newProgress) : setProgress(prevProgress);
    setPrevProgress(newProgress);
    const intervalId = setInterval(() => {
      setProgress((currentProgress) =>
        currentProgress < newProgress - 10
          ? newProgress - 10
          : currentProgress < newProgress
            ? currentProgress + 1
            : currentProgress
      );
    }, 3000);
    return () => clearInterval(intervalId);
  }, [newProgress]);

  if (isProcessing)
    return (
      <div className={styles['tab-processing']}>
        <LoaderView
          heading={`Status: ${getTabStatusMessage({ status })}...`}
          isNuLoader={documentId === 'count-report'}
          messages={LOADING_MESSAGES}
          progress={progress}
        >
          <button data-testid="cancel-report-button" onClick={handleCancelReport}>
            <span>Cancel Report</span>
          </button>
        </LoaderView>
      </div>
    );

  if (!data && !error)
    return (
      <LoaderSkeleton height={hasComments ? 535 : 255}>
        <rect x="0" y="10" rx="4" ry="4" width="250" height="50" />
        <rect x="275" y="10" rx="4" ry="4" width="250" height="50" />
        <rect x="0" y="75" rx="4" ry="4" width="1333" height="30" />
        <rect x="0" y="120" rx="4" ry="4" width="1333" height="30" />
        <rect x="0" y="165" rx="4" ry="4" width="1333" height="30" />
        <rect x="0" y="210" rx="4" ry="4" width="1333" height="30" />
        <rect x="0" y="255" rx="4" ry="4" width="1333" height="30" />
        {hasComments && <rect x="0" y="335" rx="4" ry="4" width="1333" height="200" />}
      </LoaderSkeleton>
    );

  if (!data?.jsx || error) return <Error status={error?.response?.status || 'failed-report'} />;

  return (
    <div className={styles['tab']}>
      {data?.summary?.text && (
        <KeyTakeaways data={data?.summary} isPublic={publicToken ? true : false} tabId={tabId} />
      )}
      <Visualizations
        answers={answers}
        askWhyInsightsDocument={askWhyInsightsDocument}
        askWhyInstantSurveys={askWhyInstantSurveys}
        askWhyPeopleGroups={askWhyPeopleGroups}
        askWhySurveyType={askWhySurveyType}
        data={data}
        documentId={documentId}
        exportData={exportData}
        isReadOnly={isReadOnly}
        jsx={data?.jsx}
        onAskWhySubmit={onAskWhySubmit}
        peopleGroupTypes={peopleGroupTypes}
        reportId={reportId}
        setPowerpointDownloadData={setPowerpointDownloadData}
        tabId={tabId}
      />
      {hasComments && !isReadOnly && <Comments newComment={newComment} tabId={tabId} />}
      {createdDateTime && (
        <small className={styles['tab-created-date-time']}>Created: {createdDateTime}</small>
      )}
    </div>
  );
};

Tab.defaultProps = {
  hasComments: true,
  onComplete: () => {},
};

Tab.propTypes = {
  answers: PropTypes.object,
  askWhyInsightsDocument: PropTypes.object,
  askWhyInstantSurveys: PropTypes.array,
  askWhyPeopleGroups: PropTypes.array,
  askWhySurveyType: PropTypes.object,
  createdDateTime: PropTypes.string,
  documentId: PropTypes.string,
  exportData: PropTypes.exact({
    reportName: PropTypes.string,
    reportType: PropTypes.string,
    tabName: PropTypes.string,
  }),
  hasComments: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  newComment: PropTypes.object,
  onAskWhySubmit: PropTypes.func,
  onComplete: PropTypes.func,
  peopleGroupTypes: PropTypes.array,
  setPowerpointDownloadData: PropTypes.func,
  publicToken: PropTypes.string,
  reportId: PropTypes.number,
  tabId: PropTypes.number,
};

export default memo(Tab);
