import PropTypes from 'prop-types';

import { DownloadIcon } from '@utilities';
import { setEndpoint } from '@helpers';

const DownloadXLSX = ({ id }) => {
  const downloadFileUrl = setEndpoint(`v2/api/jobs/${id}/export`);
  return (
    <a data-testid="download-xlsx" href={downloadFileUrl}>
      <DownloadIcon />
      <span>.XLSX File - Entire Report</span>
    </a>
  );
};

DownloadXLSX.propTypes = {
  id: PropTypes.number.isRequired,
};

export default DownloadXLSX;
